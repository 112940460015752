<template>
  <div
    class="flyte-picker-component"
    :class="{
      'is-range-picker': fpConfig.mode === 'range',
      'is-multi-picker': fpConfig.mode === 'multiple',
      'is-inline': config.inline,
      'has-end-selected': hasEndSelected
    }"
  >
    <div class="flyte-picker-toolbar flex">
      <div v-if="allowShortcuts" class="flyte-picker-shortcuts">
        <el-popover
          v-model="showShortcuts"
          placement="right"
          popper-class="px-0 py-1"
          trigger="manual"
        >
          <div>
            <div
              v-for="shortcut of shortcuts"
              :key="'picker-shortcut-' + shortcut.text"
              v-click-outside="() => (showShortcuts = false)"
              class="cursor-pointer hover:bg-baby-blue px-6 py-2"
              @click="onShortcutClicked(shortcut)"
            >
              {{ shortcut.text }}
            </div>
          </div>
          <template slot="reference">
            <el-tooltip
              content="Date picker shortcuts allow you to quickly select your desired dates"
              effect="light"
            >
              <a
                class="p-3 -ml-2 -mt-3 text-base outline-none"
                @click="showShortcuts = !showShortcuts"
              >
                <icon :icon="shortcutsIcon" />
              </a>
            </el-tooltip>
          </template>
        </el-popover>
      </div>

      <div v-if="allowToggle" class="flex pr-10 pb-2 ml-2 items-center">
        <a
          class="text-left date-picker-mode-toggle"
          :class="{ 'is-active-mode': fpConfig.mode === 'range' }"
          @click="$emit('config', { mode: 'range' })"
        >
          Range
        </a>
        <span class="ml-2">/</span>
        <a
          class="text-right ml-2 date-picker-mode-toggle"
          :class="{ 'is-active-mode': fpConfig.mode === 'multiple' }"
          @click="$emit('config', { mode: 'multiple' })"
        >
          Multiple
        </a>
      </div>

      <div
        v-if="clearable && value.length > 0"
        class="flyte-picker-clear flex-grow text-right"
      >
        <a class="text-gray hover:text-dark-gray" @click="$emit('change', [])">
          <icon :icon="plusCircleIcon" class="rotate-45" />
        </a>
      </div>
    </div>

    <div class="flyte-picker-calendar">
      <input
        :id="pickerId + '-flyte-picker'"
        ref="input"
        class="flyte-picker flyte-picker-hidden-input"
      />
    </div>
  </div>
</template>

<script>
import { extend } from 'lodash';
import flatpickr from 'flatpickr';
import moment from 'moment';
import {
  calendarCheckO as shortcutsIcon,
  plusCircle as plusCircleIcon
} from '@/vendor/icons';
import { PICKER_SHORTCUTS } from '@/utils/date';

export default {
  props: {
    id: { type: String, default: null },
    config: { type: Object, default: null },
    value: { type: [Array, String], default: null },
    firstDate: { type: String, default: null },
    ignoreValueChange: Boolean,
    clearable: Boolean,
    allowToggle: Boolean,
    allowShortcuts: Boolean,
    shortcuts: {
      type: Array,
      default: () => PICKER_SHORTCUTS.ALL
    }
  },

  data() {
    let config = extend(
      {
        dateFormat: 'Y-m-d',
        altFormat: 'm/d/Y',
        altInput: true,
        monthSelectorType: 'static',
        defaultDate: this.value || moment().toISOString(),
        mode: 'single',
        minDate: moment().toISOString(),
        maxDate: moment()
          .add(18, 'months')
          .toISOString(),
        onChange: (selectedDates, dateStr) => {
          this.$emit('input', dateStr);
          this.$emit('change', selectedDates, dateStr);

          if (this.isUserSelect) {
            this.$emit('select', selectedDates, dateStr);
          }
        },
        onDayCreate: (selectedDates, selectedDatesStr, fp, el) => {
          el.onmouseup = this.onDateClicked;
          this.$emit('date-render', {
            el,
            date: el.dateObj,
            selectedDates,
            selectedDatesStr,
            fp
          });
        }
      },
      this.config
    );

    return {
      pickerId: this.id || 'picker-' + this._uid,
      isUserSelect: false,
      fpConfig: config,
      flatpickr: null,
      showShortcuts: false,

      // Icons
      plusCircleIcon,
      shortcutsIcon
    };
  },

  computed: {
    hasEndSelected() {
      return this.value && this.value[1];
    }
  },

  watch: {
    config: {
      deep: true,
      handler(newConfig) {
        this.fpConfig = extend(this.fpConfig, newConfig);

        for (let c in newConfig) {
          this.flatpickr.set(c, newConfig[c]);
        }
      }
    },

    value(newValue, oldValue) {
      if (newValue && !this.ignoreValueChange) {
        // The onChange method will get triggered here, so make sure we do not send the select event
        this.isUserSelect = false;

        if (Array.isArray(newValue)) {
          if (newValue.length > 0) {
            let diff = newValue.filter(
              newDate =>
                !(oldValue || []).find(oldDate =>
                  moment(oldDate).isSame(newDate)
                )
            );

            if (diff.length > 0) {
              this.jumpToDate(diff[0]);
            } else {
              this.jumpToCurrent();
            }
          } else {
            this.$emit('change', newValue);
          }
        } else if (!(newValue instanceof Date)) {
          // Make sure we are setting the correct day (casting to Date from string at the wrong time may fuck up)
          newValue = new Date(newValue);
          this.jumpToDate(newValue);
        }

        this.flatpickr.setDate(newValue);

        this.isUserSelect = true;
      }
    },

    firstDate() {
      this.flatpickr.set('defaultDate', this.value);
      this.jumpToDate(this.firstDate);
    }
  },

  beforeDestroy() {
    this.flatpickr.destroy();
  },

  mounted() {
    // Initialize the flatpickr instance on the flyte-picker input element
    this.flatpickr = flatpickr(this.$refs.input, this.fpConfig);

    // Give the altInput field the id of the original element (in case there is a label pointing to it)
    if (this.flatpickr.altInput) {
      // set id to the alt input
      this.flatpickr.altInput.id = this.pickerId;
    }

    // Any changes made at this point presumably were from the user changing the selectedDates.
    this.isUserSelect = true;

    if (this.firstDate) {
      this.jumpToDate(this.firstDate);
    } else if (Array.isArray(this.value) && this.value.length > 0) {
      this.jumpToDate(this.value[0]);
    }
  },

  methods: {
    onDateClicked(e) {
      let date = e.target.dateObj;

      setTimeout(() => {
        let dateEl = this.getDateElement(date);

        this.$emit(
          'date-click',
          date,
          dateEl && dateEl.classList.contains('selected'),
          dateEl
        );
      }, 10);
    },

    getDateElement(dateObj) {
      if (this.flatpickr.days) {
        return Array.from(this.flatpickr.days.children).find(
          el =>
            moment(el.dateObj).format('YYYY-MM-DD') ===
            moment(dateObj).format('YYYY-MM-DD')
        );
      } else {
        return null;
      }
    },

    jumpToDate(date) {
      this.$nextTick(() => {
        this.flatpickr.jumpToDate(date);
      });
    },
    /**
     * flatpickr likes to jump to a month with a selected date, but lets just keep it on current month
     * as a better user experience
     */
    jumpToCurrent() {
      let year = this.flatpickr.currentYear;
      let month = this.flatpickr.currentMonth + 1;

      this.jumpToDate(
        new Date(`${year}-${month < 10 ? '0' + month : month}-15`)
      );
    },
    onShortcutClicked(shortcut) {
      let dates =
        this.fpConfig.mode === 'range' ? shortcut.range() : shortcut.multiple();

      // Do not allow setting a range outside the allowed min / max dates
      if (this.fpConfig.mode === 'range') {
        if (
          this.fpConfig.minDate &&
          moment(dates[0]).isBefore(this.fpConfig.minDate)
        ) {
          dates[0] = this.fpConfig.minDate;
        }

        if (
          this.fpConfig.maxDate &&
          moment(dates[1]).isAfter(this.fpConfig.maxDate)
        ) {
          dates[1] = this.fpConfig.maxDate;
        }
      }

      this.$emit('change', dates);
      this.showShortcuts = false;
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/components/flyte-picker';
</style>
